<div class="d-inline-block">
  <div *ngFor="let break of model; index as i">
    <ng-container *ngIf="!mealbreak || i == 0">
      <swe-break [(model)]="break.Break" [(modelStart)]="break.BreakStart" (modelChange)="manageChange()" [ignoreConstraints]="ignoreConstraints" [alwaysShowStart]="alwaysShowStart" [mealbreakAccess]="false" [readonly]="readonly" [disabled]="disabled||isRedo||mealbreak" [disabledStart]="disabledStart||isRedo||mealbreak" [minStart]="minStart" [maxStart]="maxStart" [markChanges]="markChanges"></swe-break>
      <div class="d-inline-block ms-1" *ngIf="!disabled&&!readonly&&!mealbreak">
        <a class="swe-no-link" href="javascript:void(0);" [attr.title]="languageService.getItem(17)" (click)="remove(break)" *ngIf="reset||model.length>1"><i class="bi bi-swe-fw bi-dash-circle-fill"></i></a>
        <i class="bi bi-swe-fw" *ngIf="!(reset||model.length>1)"></i>
        <a class="swe-no-link ms-2" href="javascript:void(0);" [attr.title]="languageService.getItem(100)" (click)="add()" *ngIf="!onlyOne && model.length - 1 == i"><i class="bi bi-swe-fw bi-plus-circle-fill"></i></a>
      </div>
    </ng-container>
    <ng-container *ngIf="i == 0 && mealbreakAccess > 0">
      <div class="d-inline-block ms-3">
        <i class="bi bi-swe-fw bi-swe bi-stopwatch-fill" [title]="languageService.getItem(1415)" [attr.aria-label]="languageService.getItem(1415)"></i>
      </div>
      <div class="d-inline-block">
        <swe-element type="System.Boolean" (modelChange)="manageMealbreak()" [disabled]="mealbreakAccess < 2||disabled||readonly" [model]="mealbreak" [label]="languageService.getItem(1415)" [(setFocus)]="mealbreakfocus"></swe-element>
      </div>
    </ng-container>
  </div>
</div>
<div class="d-inline-block" *ngIf="model.length==0&&!disabled&&!readonly">
  <span class="align-middle small me-3" *ngIf="reset">({{languageService.getItem(982)}})</span>
  <a class="swe-no-link" href="javascript:void(0);" [attr.title]="languageService.getItem(981)" (click)="redo()" *ngIf="reset&&!mealbreak"><i class="bi bi-swe-fw bi-arrow-clockwise" [ngClass]="{'bi-swe-spin': isRedo}"></i></a>
  <a class="swe-no-link ms-2" href="javascript:void(0);" [attr.title]="languageService.getItem(100)" (click)="add()" *ngIf="!mealbreak"><i class="bi bi-swe-fw bi-plus-circle-fill"></i></a>
</div>
