import { Component, OnInit, OnChanges, HostListener, enableProdMode } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Title } from '@angular/platform-browser';

import { MenuService } from './_services/menu.service';
import { AuthenticationService } from './_services/authentication.service';
import { LanguageService } from './_services/language.service';
import { SettingService } from './_services/setting.service';
import { DataService } from './_services/data.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnChanges {

  private _newversion: boolean = false;

  constructor(
    public authenticationService: AuthenticationService,
    public menuService: MenuService,
    public settingService: SettingService,
    public languageService: LanguageService,
    private dataService: DataService,
    private swUpdate: SwUpdate,
    private title: Title,
    private route: ActivatedRoute
  ) {
    
    this.route.queryParams.subscribe((params) => {
      let culture = params['culture'];
      if (culture) {
        this.dataService.culture = culture;
        this.languageService.reloadByCulture(culture);
      }
    });

  }

  ngOnInit() {
    console.log('App Component OnInit');
    
    this.initSwUpdate();

    this.load();
  }

  ngOnChanges() {
    
  }

  @HostListener('window:keydown.control.s', ['$event'])
  @HostListener('window:keydown.meta.s', ['$event'])
  @HostListener('window:keydown.control.alt.r', ['$event'])
  @HostListener('window:keydown.meta.alt.r', ['$event'])
  @HostListener('window:keydown.control.alt.l', ['$event'])
  @HostListener('window:keydown.meta.alt.l', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.preventDefault();

    let log = '';
    if (event.ctrlKey) { log += 'Ctrl + '; }
    if (event.metaKey) { log += 'Meta + '; }
    if (event.altKey) { log += 'Alt  + '; }
    log += event.keyCode + ' (' + event.key + ')';
    console.log(log);

    this.settingService.keyEvent(event);


  }

  //Properties
  public get newversion() {
    return this._newversion;
  }
  public set newversion(val) {
    this._newversion = val;
  }



  //Methods
  public newversionaccept(e) {
    this._newversion = false;
    window.location.reload();
  }
  public newversiondecline(e) {
    this._newversion = false;
  }




  //Functions
  private load() {

    this.dataService.basicRequest('/api/v1/general/customersettings', 'GET', {})
      .subscribe(res => {
        if (res) {

          this.title.setTitle('SwedeTime - ' + res.Name);
        }
      });

  }

  //ServiceWorker
  private initSwUpdate() {
    if (this.swUpdate.isEnabled) {
      console.log('SwUpdate enabled');
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === "VERSION_READY") {
          console.log('New version detected');

          this._newversion = true;
        }
      });
    }
  }
}
