<swe-pageheader [header]="languageService.getItem(742)"></swe-pageheader>
<div class="row  py-2" *ngIf="permissionService.permissions.Help>0">
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(743)" [open]="true" [hasCollapse]="true" [hasRefresh]="false" *ngIf="permissionService.permissions.Help>0">
      <!--Shift Colors-->
      <div class="mb-4">
        <label>{{languageService.getItem(755)}}</label>
        <div class="mb-3"><i class="bi bi-swe-border alert-danger border-danger">&nbsp;&nbsp;&nbsp;</i><span>&nbsp;=&nbsp;{{languageService.getItem(752)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border alert-warning border-warning">&nbsp;&nbsp;&nbsp;</i><span>&nbsp;=&nbsp;{{languageService.getItem(753)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border alert-success border-success">&nbsp;&nbsp;&nbsp;</i><span>&nbsp;=&nbsp;{{languageService.getItem(754)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border swe-timeline-minustime-help border-danger">&nbsp;&nbsp;&nbsp;</i><span>&nbsp;=&nbsp;{{languageService.getItem(1458)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border swe-timeline-plustime-help border-success">&nbsp;&nbsp;&nbsp;</i><span>&nbsp;=&nbsp;{{languageService.getItem(1459)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border swe-timeline-abscent-notfull-help border-danger">&nbsp;&nbsp;&nbsp;</i><span>&nbsp;=&nbsp;{{languageService.getItem(1460)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border swe-timeline-abscent-full-help border-success">&nbsp;&nbsp;&nbsp;</i><span>&nbsp;=&nbsp;{{languageService.getItem(1461)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border swe-bg-warning-half border-success">&nbsp;&nbsp;&nbsp;</i><span>&nbsp;=&nbsp;{{languageService.getItem(1462)}}</span></div>
      </div>
      <!--Shift Status-->
      <div class="mb-4">
          <label>{{languageService.getItem(756)}}</label>
          <div class="mb-3"><i class="bi bi-swe-border bi-square"></i><span>&nbsp;=&nbsp;{{languageService.getItem(23)}}&nbsp;{{languageService.getItem(1463)}}</span></div>
          <div class="mb-3"><i class="bi bi-swe-border bi-arrow-up-right-square"></i><span>&nbsp;=&nbsp;{{languageService.getItem(24)}}</span></div>
          <div class="mb-3"><i class="bi bi-swe-border bi-key-fill"></i><span>&nbsp;=&nbsp;{{languageService.getItem(25)}}</span></div>
          <div class="mb-3"><i class="bi bi-swe-border bi-clock-fill"></i><span>&nbsp;=&nbsp;{{languageService.getItem(26)}}</span></div>
          <div class="mb-3"><i class="bi bi-swe-border bi-check-lg"></i><span>&nbsp;=&nbsp;{{languageService.getItem(27)}}</span></div>
          <div class="mb-3"><i class="bi bi-swe-border bi-lock-fill"></i><span>&nbsp;=&nbsp;{{languageService.getItem(28)}}</span></div>
      </div>
      <!--Other Shift Icons-->
      <div class="mb-4">
        <label>{{languageService.getItem(757)}}</label>
        <div class="mb-3"><i class="bi bi-swe-border bi-swe-bookingtype-replaceable"></i><span>&nbsp;=&nbsp;{{languageService.getItem(423)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border bi-swe-bookingtype-standby"></i><span>&nbsp;=&nbsp;{{languageService.getItem(279)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border bi-swe-bookingtype-abscent"></i><span>&nbsp;=&nbsp;{{languageService.getItem(280)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border bi-swe-bookingtype-decline"></i><span>&nbsp;=&nbsp;{{languageService.getItem(1106)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border bi-eye-fill"></i><span>&nbsp;=&nbsp;{{languageService.getItem(339)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border bi-link-45deg"></i><span>&nbsp;=&nbsp;{{languageService.getItem(203)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border bi-list-ul"></i><span>&nbsp;=&nbsp;{{languageService.getItem(741)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border bi-chat-fill"></i><span>&nbsp;=&nbsp;{{languageService.getItem(538)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border bi-chat"></i><span>&nbsp;=&nbsp;{{languageService.getItem(205)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border bi-send"></i><span>&nbsp;=&nbsp;{{languageService.getItem(178)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe-border bi-shield-shaded"></i><span>&nbsp;=&nbsp;{{languageService.getItem(698)}}</span></div>
      </div>
      <!--Timereport Icons-->
      <div class="mb-4">
          <label>{{languageService.getItem(858)}}</label>
          <div class="mb-3"><i class="bi bi-swe bi-box-arrow-in-right swe-grayed-out"></i>&nbsp;<i class="bi bi-swe bi-box-arrow-right swe-grayed-out"></i><span>&nbsp;=&nbsp;{{languageService.getItem(749)}}</span></div>
          <div class="mb-3"><i class="bi bi-swe bi-box-arrow-in-right"></i>&nbsp;<i class="bi bi-swe bi-box-arrow-right swe-grayed-out"></i><span>&nbsp;=&nbsp;{{languageService.getItem(750)}}</span></div>
          <div class="mb-3"><i class="bi bi-swe bi-box-arrow-in-right"></i>&nbsp;<i class="bi bi-swe bi-box-arrow-right"></i><span>&nbsp;=&nbsp;{{languageService.getItem(751)}}</span></div>
          <div class="mb-3"><i class="bi bi-swe-border bi-clock-fill swe-grayed-out"></i>&nbsp;<i class="bi bi-swe-border bi-check-lg swe-grayed-out"></i>&nbsp;<i class="bi bi-swe-border bi-lock-fill swe-grayed-out"></i>&nbsp;=&nbsp;{{languageService.getItem(177)}}</div>
          <div class="mb-3"><i class="bi bi-swe-border bi-clock-fill"></i>&nbsp;<i class="bi bi-swe-border bi-check-lg swe-grayed-out"></i>&nbsp;<i class="bi bi-swe-border bi-lock-fill swe-grayed-out"></i>&nbsp;=&nbsp;{{languageService.getItem(26)}}</div>
          <div class="mb-3"><i class="bi bi-swe-border bi-clock-fill"></i>&nbsp;<i class="bi bi-swe-border bi-check-lg"></i>&nbsp;<i class="bi bi-swe-border bi-lock-fill swe-grayed-out"></i>&nbsp;=&nbsp;{{languageService.getItem(27)}}</div>
          <div class="mb-3"><i class="bi bi-swe-border bi-clock-fill"></i>&nbsp;<i class="bi bi-swe-border bi-check-lg"></i>&nbsp;<i class="bi bi-swe-border bi-lock-fill"></i>&nbsp;=&nbsp;{{languageService.getItem(28)}}</div>
      </div>
      <!--Other Icons-->
      <div class="mb-4">
        <label>{{languageService.getItem(758)}}</label>
        <div class="mb-3"><i class="bi bi-swe bi-calendar-plus"></i><span>&nbsp;=&nbsp;{{languageService.getItem(100) + ' ' + languageService.getItem(107)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe bi-calendar-check"></i><span>&nbsp;=&nbsp;{{languageService.getItem(100) + ' ' + languageService.getItem(110)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe bi-person-plus-fill"></i><span>&nbsp;=&nbsp;{{languageService.getItem(100) + ' ' + languageService.getItem(2)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe bi-newspaper"></i><span>&nbsp;=&nbsp;{{languageService.getItem(100) + ' ' + languageService.getItem(131)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe bi-search"></i><span>&nbsp;=&nbsp;{{languageService.getItem(744)}}</span></div>
        <div class="mb-3"><i class="bi bi-swe bi-dash-circle"></i><span>&nbsp;=&nbsp;{{languageService.getItem(745)}}</span></div>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(840)" [open]="true" [hasCollapse]="true" [hasRefresh]="false" *ngIf="permissionService.permissions.Help>0">
      <div class="list-group">
        <div class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let document of documents">
          <div class="col-9 col-md-11">
              <a class="text-primary swe-click" (click)="download(document.Value)">{{document.Value}}</a>
          </div>
          <div class="col-3 col-md-1">
              <span *ngIf="permissionService.permissions.Help>2" class="swe-click" (click)="delete(document.Value)"><i class="bi bi-swe-fw bi-swe bi-trash-fill"></i></span>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-3" *ngIf="permissionService.permissions.Help>1">
        <swe-element [bottomMargin]="0" [type]="'Internetplatsen.Document'" [label]="languageService.getItem(853)" [statusLabel]="0" [(model)]="file" [container]="documentcontainer()"></swe-element>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="upload()"><i class="bi bi-upload"></i>&nbsp;{{languageService.getItem(853)}}</button>
        </div>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(134)" [open]="true" [hasCollapse]="true" [hasRefresh]="true" (refreshChange)="search()" *ngIf="permissionService.permissions.Help>0">
      <span swe-rightcommand class="bi-swe-pull-right bg-success rounded text-white"><i class="bi bi-plus-lg bi-swe-fw swe-click" (click)="goto(0)" *ngIf="permissionService.permissions.News>1"></i></span>
      <ng-container *ngFor="let item of news">
        <div class="list-group-item" [ngClass]="{'list-group-item-warning': item.NotPublished,'list-group-item-danger': item.Expired}" *ngIf="(showpublic || !item.IsPublic)">
          <!--List mode-->
          <div class="d-flex flex-wrap" *ngIf="!item.isedit && item.Id>0 && (showpublic || !item.IsPublic)"
               [ngClass]="{'swe-click': (item.Access>1||(permissionService.permissions.NewsAll>0&&item.Levels.length==0))}"
               (click)="(item.Access>1||(permissionService.permissions.NewsAll>0&&item.Levels.length==0))&&goto(item.Id)">
            <div class="col-12 col-md-2 col-lg-1">
              <img class="swe-circle-image" alt="{{item.Title}}" *ngIf="item.Image.length>0" sweImage imageFolder="announcementimages" [imageFile]="item.Image" imageWidth="60" imageHeight="60" />
            </div>
            <div class="col-12 col-md-10 col-lg-11 d-flex flex-wrap">
              <div class="col-12 col-md-6"><b>{{item.Title}}</b></div>
              <div class="col-12 col-md-6"><i><span *ngIf="item.Role">({{item.Role}})&nbsp;</span><span class="me-1" *ngFor="let level of item.Levels | slice:0:3;">{{level.LevelName}}</span><span *ngIf="item.Levels.length > 3">...</span></i></div>
              <div class="col-12 col-md-12" [innerHTML]="wash(item.Body)"></div>
              <div class="col-12 col-md-12" *ngIf="item.Link.length>0"><a class="text-primary swe-click" (click)="openLink(item, $event)">{{item.LinkName}}</a></div>
              <div class="col-12 col-md-12" *ngIf="permissionService.permissions.ShowNewsPublishInfo"><i>{{dateTimeService.format(item.Publish)}}</i></div>
            </div>
          </div>
        </div>
      </ng-container>
    </swe-card>
  </div>
</div>
