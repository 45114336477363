<swe-pageheader [header]="languageService.getItem(134)">
  <span swe-hits>
    &nbsp;(#{{id}})
  </span>
</swe-pageheader>
<div class="row py-2" *ngIf="permissionService.permissions.News>0">
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(131)" [hasRefresh]="false" [hasCollapse]="false">
      <swe-element [statusLabel]="1" [(model)]="news.Title" [access]="news.Access" [label]="languageService.getItem(130)" [type]="'System.String'"></swe-element>
      <swe-element [statusLabel]="1" [(model)]="news.Body" [access]="news.Access" [label]="languageService.getItem(131)" [type]="'System.TextArea'"></swe-element>
      <swe-element [statusLabel]="1" [(model)]="news.LinkUrl" [access]="news.Access" [label]="languageService.getItem(807)" [type]="'System.Link'" [container]="linkcontainer(news.LinkUrl)"></swe-element>
      <swe-element [statusLabel]="1" [(model)]="news.Image" [access]="news.Access" (modelChange)="upload($event, news.Id)" [label]="languageService.getItem(195)" [container]="documentcontainer(news.Image)" [type]="'Internetplatsen.Document'" [disabled]="news.Access<2&&!(permissionService.permissions.NewsAll>0&&news.LevelId==0)"></swe-element>
      <swe-element [statusLabel]="1" [(model)]="news.RoleId" [access]="news.Access" [label]="languageService.getItem(82)" [optional]="true" [items]="generalService.roles" [type]="'System.List'"></swe-element>
      <swe-levelsearch [bottomMargin]="2" [onlyone]="false" (modelChange)="addLevel($event)" [label]="languageService.getItem(14)" [statusLabel]="1"></swe-levelsearch>
      <div class="list-group pt-0 ps-1 offset-{{permissionService.permissions.ProfileLabel}}" *ngIf="news.Levels&&news.Levels.length>0">
        <ng-container *ngFor="let level of news.Levels; index as i">
          <div class="list-group-item" [ngClass]="{'mb-3': i == news.Levels.length -1}" *ngIf="!level.IsDeleted">
            {{level.LevelName}}
            <i class="bi bi-swe bi-x bi-swe-pull-right swe-click" (click)="removeLevel(level)"></i>
          </div>
        </ng-container>
      </div>
      <swe-element [statusLabel]="1" [(model)]="news.Publish" [access]="news.Access" [label]="languageService.getItem(132)" [type]="'System.DateTime'" (modelChange)="manageExpired($event)"></swe-element>
      <swe-element [statusLabel]="1" [(model)]="news.Expires" [access]="news.Access" [label]="languageService.getItem(133)" [type]="'System.DateTime'"></swe-element>
      <swe-element [statusLabel]="1" [(model)]="news.ShowAt" [access]="news.Access" [label]="languageService.getItem(986)" [type]="'System.MultiList'" [items]="items" [container]="{isbit:true}"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save(news)" *ngIf="news.Access>1||(permissionService.permissions.NewsAll>0&&news.LevelId==0)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
        <button class="btn btn-danger" (click)="delete(news)" *ngIf="id>0&&(news.Access>2||(permissionService.permissions.NewsAll>0&&news.LevelId==0))"><i class="bi bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
      </div>
    </swe-card>
  </div>
</div>

