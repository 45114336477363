import { Injectable, NgZone } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ServerEventService {

  //Class members
  private eventSource: EventSource;

  //Contructor
  constructor(
    private zone: NgZone
  ) {

  }

  //Create EventSource instance
  private getEventSource(): EventSource {
    return new EventSource('api/v1/system/events', { withCredentials: true });
  }


  //Connect
  public connect(): Observable<Event> {

    //Close if active
    if (this.eventSource) {
      this.close();
    }

    //Create new instance
    this.eventSource = this.getEventSource();
    
    return new Observable((subscriber: Subscriber<Event>) => {

      this.manageSubscriber(subscriber);

    });
  }

  private manageSubscriber(subscriber: Subscriber<Event>) {

    console.log('Add SSE subscriber');

    this.eventSource.onerror = error => {
      console.log('Error SSE: ' + this.eventSource.readyState);
    };

    this.eventSource.onopen = message => {
      console.log('Open SSE');
    };

    this.eventSource.onmessage = message => {

      console.log('New SSE');
      this.zone.run(() => subscriber.next(message));
      
    };

    this.eventSource.addEventListener('message', message => {

      console.log('New SSE (EventListener)');
      this.zone.run(() => subscriber.next(message));

    });

  }



  //Close
  public close(): void {

    console.log('Close SSE subscriber');

    if (!this.eventSource) {
      return;
    }

    this.eventSource.close();
    this.eventSource = null;
  }
}

